<template>
  <div class="player-container">
    <slot
      v-if="!contentIsLoaded"
      name="skeleton"
    />
  </div>
</template>

<script>
import showMessage from '@/helpers/messaging-service';

export default {
	name: 'PVastPlayer',
	components: {
	},
	props: {
		bannerID: {
			type: String,
		},
		maxVideoDurationTime: {
			type: Number,
			default: 45, // seconds
		},
		videoConfigFromProps: {
			type: Object,
			default: () => ({}),
		},
		vpaidScriptFromProps: {
			type: String, // base64
		},
		filesFromProps: {
			type: Array,
			default: () => ([]),
		},
		fileFromStore: {
			type: File,
		},
		landingPageUrl: {
			type: String,
		},
	},
	emits: ['adFinished'],
	data() {
		return {
			videoConfig: {},
			vpaidScript: '',
			files: [],
			contentIsLoaded: false,
		};
	},
	computed: {
		isBrandLift() {
			return this.$route.query.isBrandLift === 'true';
		},
		cdnLink() {
			if (this.bannerID === '') {
				return 'https://cdn-files.adx.com.ru/631061bed41e0663b3262744/0';
			}
			return `https://cdn-files.adx.com.ru/banner_${this.bannerID}/0`;
		},
		defaultVpaidScript() {
			return this.$store.state.PlayerCustomization.Form.vpaidScript;
		},
	},
	watch: {},
	mounted() {
		this.addGoogleImaSdkScript().onload = async () => {
			if (this.fileFromStore) {
				this.convertFileToVideo(this.fileFromStore);
				return;
			}
			await this.setVideoConfig();
			this.playVideo(this.cdnLink);
		};
	},
	beforeUnmount() {
		this.removeGoogleImaSdkScript();
	},
	methods: {
		addGoogleImaSdkScript() {
			const googleImaSdkScript = document.createElement('script');
			googleImaSdkScript.setAttribute('id', 'googleImaSdkScript');
			googleImaSdkScript.setAttribute('type', 'application/javascript');
			googleImaSdkScript.setAttribute('src', 'https://imasdk.googleapis.com/js/sdkloader/ima3.js');
			document.head.appendChild(googleImaSdkScript);
			return googleImaSdkScript;
		},
		removeGoogleImaSdkScript() {
			const googleImaSdkScript = document.getElementById('googleImaSdkScript');
			if (googleImaSdkScript) {
				googleImaSdkScript.remove();
			}
		},
		scaleAdContainerSize(video, container) {
			video.style.height = '100%';
			const widthToWidescreenFormat = container.offsetHeight * 1.78; // 1.78 height multiplier to render player in 16:9
			video.style.width = `${widthToWidescreenFormat}px`;
			video.style.marginLeft = `${(container.offsetWidth - widthToWidescreenFormat) / 2}px`;
		},
		convertVideoConfigToJSON(videoSrc = '') {
			function replacer(key, value) {
				if (value === 'true') {
					return true;
				}
				if (value === 'false') {
					return false;
				}
				if (value === 'undefined' || value === 'null') {
					return '';
				}
				return value;
			}

			if (this.isBrandLift) {
				return JSON.stringify({ ...this.videoConfig, overlay: ['61fa780fd41e068f818fe6e9'] }, replacer);
			}

			return JSON.stringify({ ...this.videoConfig, files: [...this.files], videos: [{ url: videoSrc, mimetype: 'video/mp4' }] }, replacer);
		},
		async setVideoConfig() {
			const useConfigFromProps = Object.keys(this.videoConfigFromProps).length !== 0 && !this.$route.query.playerConfigId;
			if (useConfigFromProps) {
				this.videoConfig = { ...this.videoConfigFromProps };
				this.vpaidScript = this.vpaidScriptFromProps;
				this.files = this.filesFromProps;
				return;
			}

			if (!this.isBrandLift) {
				const [err, playerConfig] = await this.$fetch.playerConfigAPI.getPlayerCustomizationConfigPreview(this.$route.query.playerConfigId);
				if (err) {
					showMessage('playerUploadingErrorMSG');
					this.backToUploadingPage();
					return;
				}

				const { vpaidScript, videoConfig, files } = playerConfig;
				this.vpaidScript = vpaidScript;
				this.videoConfig = videoConfig;
				this.files = files;

				if (this.$route.query.subtitles) {
					this.videoConfig.subs = this.$route.query.subtitles;
				}
				return;
			}

			const [err, brandliftConfig] = await this.$fetch.brandLiftAPI.getBrandLift(this.$route.query.playerConfigId);
			if (err) {
				showMessage('playerUploadingErrorMSG');
				this.backToUploadingPage();
				return;
			}

			this.vpaidScript = '';
			this.videoConfig = brandliftConfig;
		},
		buildVAST(videoDuration, videoSrc) {
			if (!this.isBrandLift) {
				return `
				<VAST version="3.0">
					<Ad>
						<InLine>
							<AdSystem>
								<![CDATA[ Plazkart ]]>
							</AdSystem>
							<Creatives>
								<Creative sequence="1">
									<Linear>
										<Duration>00:00:${videoDuration}</Duration>
										<TrackingEvents/>
										<AdParameters>
											<![CDATA[ ${this.convertVideoConfigToJSON(videoSrc)} ]]>
										</AdParameters>
										<VideoClicks>
											<ClickThrough><![CDATA[ ${this.landingPageUrl} ]]></ClickThrough>
										</VideoClicks>
										<MediaFiles>
											<MediaFile type="application/javascript" apiFramework="VPAID">
												<![CDATA[ ${this.vpaidScript || this.defaultVpaidScript} ]]>
											</MediaFile>
										</MediaFiles>
									</Linear>
								</Creative>
							</Creatives>
						</InLine>
					</Ad>
				</VAST>
			`;
			}

			return `
			<VAST version="3.0">
					<script/>
					<Ad>
							<InLine>
									<AdSystem>
											<![CDATA[ Plazkart ]]>
									</AdSystem>
									<AdTitle/>
									<Creatives>
											<Creative>
													<Linear>
															<Duration>00:00:15</Duration>
															<AdParameters>
																	<![CDATA[ ${this.convertVideoConfigToJSON()}]]>
															</AdParameters>
															<TrackingEvents/>
															<VideoClicks/>
															<MediaFiles>
																	<MediaFile delivery="" type="application/javascript"
																			width="0" height="0" apiFramework="VPAID">
																				<![CDATA[ ${this.videoConfig.storageUrl} ]]>
																			</MediaFile>
															</MediaFiles>
													</Linear>
											</Creative>
									</Creatives>
									<Description/>
									<Survey/>
							</InLine>
					</Ad>
			</VAST>
			`;
		},
		playVideo(videoSrc) {
			const video = document.createElement('video');
			const adContainerWrapper = document.querySelector('.player-container');
			const adContainer = document.createElement('div');
			adContainerWrapper.append(adContainer);
			this.scaleAdContainerSize(adContainer, adContainerWrapper);
			video.preload = 'metadata';
			video.src = videoSrc;
			video.onerror = () => {
				showMessage('commonErrorMSG');
				this.backToUploadingPage();
			};
			video.onloadedmetadata = () => {
				const videoDuration = Math.round(video.duration);
				if (videoDuration > this.maxVideoDurationTime) {
					showMessage('maxVideoDurationErrorMSG', this.maxVideoDurationTime);
					this.backToUploadingPage();
					return;
				}
				let adsManager;
				google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE); // eslint-disable-line no-undef

				const showAd = () => {
					this.contentIsLoaded = true;
					adsManager.start();
				};

				const observer = new IntersectionObserver(((entry) => {
					if (entry[0].isIntersecting && !this.contentIsLoaded) {
						showAd();
					}
				}), {
					threshold: 0.25,
				});

				const onAdError = (adErrorEvent) => {
					try {
						adsManager.destroy();
					} catch (e) {
						showMessage('error', adErrorEvent.getError());
						this.backToUploadingPage();
					}
				};

				const onAdLoaded = () => {
					if (!observer) {
						showAd();
					}
					observer.observe(adContainerWrapper);
				};

				const onAdComplete = () => {
					this.contentIsLoaded = false;
					adContainer.hidden = true;
					this.$emit('adFinished');
				};

				const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
					const adsRenderingSettings = new google.ima.AdsRenderingSettings(); // eslint-disable-line no-undef
					adsRenderingSettings.enablePreloading = true;

					adsManager = adsManagerLoadedEvent.getAdsManager(video, adsRenderingSettings); // eslint-disable-line no-undef
					adsManager.setVolume(0); // eslint-disable-line no-undef

					adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, onAdError); // eslint-disable-line no-undef
					adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, onAdLoaded); // eslint-disable-line no-undef
					adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, onAdComplete); // eslint-disable-line no-undef

					adsManager.init(adContainer.clientWidth, adContainer.clientHeight, google.ima.ViewMode.NORMAL); // eslint-disable-line no-undef

					window.addEventListener('resize', () => {
						adsManager.resize(adContainer.clientWidth, adContainer.clientHeight, google.ima.ViewMode.NORMAL); // eslint-disable-line no-undef
					});
				};

				const adDisplayContainer = new google.ima.AdDisplayContainer(adContainer); // eslint-disable-line no-undef
				adDisplayContainer.initialize();

				const adsLoader = new google.ima.AdsLoader(adDisplayContainer); // eslint-disable-line no-undef
				adsLoader.addEventListener(google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded); // eslint-disable-line no-undef
				adsLoader.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, onAdError); // eslint-disable-line no-undef

				const adsRequest = new google.ima.AdsRequest(); // eslint-disable-line no-undef
				adsRequest.adsResponse = this.buildVAST(videoDuration, video.src);
				adsLoader.requestAds(adsRequest); // eslint-disable-line no-undef
			};
		},
		convertFileToVideo(file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => this.playVideo(reader.result);
		},
		backToUploadingPage() {
			const redirectToLauncherPage = () => this.$router.push({ name: 'PreviewLauncher', params: { pageMode: 'launcher' } });
			setTimeout(() => {
				redirectToLauncherPage();
			}, 2500);
		},
	},
};
</script>

<style scoped>
.player-container {
	min-height: 180px;
}
</style>
