import BaseInfo from '@/store/modules/campaign/campaign-base-info';
import RollType from '@/store/modules/campaign/campaign-roll-type';
import Segments from '@/store/modules/campaign/campaign-segments';
import Geo from '@/store/modules/campaign/campaign-geo/campaign-geo';
import Ssps from '@/store/modules/campaign/campaign-ssps';
import DomainsList from '@/store/modules/campaign/campaign-domains-list';
import IpsList from '@/store/modules/campaign/campaign-ips-list';
import SiteCategories from '@/store/modules/campaign/campaign-site-categories';
import EmotionalBckg from '@/store/modules/campaign/campaign-emotional-bckg';
import BrandSafety from '@/store/modules/campaign/campaign-brand-safety';
import DeviceType from '@/store/modules/campaign/campaign-device-type';
import OperatingSystem from '@/store/modules/campaign/campaign-operating-system';
import Operators from '@/store/modules/campaign/campaign-operators';
import BidType from '@/store/modules/campaign/campaign-bid-type';
import BuyingType from '@/store/modules/campaign/campaign-buying-type';
import Calendar from '@/store/modules/campaign/campaign-calendar';
import Limits from '@/store/modules/campaign/campaign-limits';
import Banners from '@/store/modules/campaign/campaign-banners';
import Kpi from '@/store/modules/campaign/campaign-kpi';
import Retargeting from '@/store/modules/campaign/campaign-retargeting';
import TrafType from '@/store/modules/campaign/campaign-traf-type';
import FraudScore from '@/store/modules/campaign/campaign-fraudscore';
import TargetSettings from '@/store/modules/campaign/campaign-target-settings';
import DeviceManufactures from '@/store/modules/campaign/campaign-device-manufactures';
import OsVersion from '@/store/modules/campaign/camapign-os-version';

export default {
	namespaced: true,
	getters: {
		translatedToBackendKeysCampaignData(state, getters, rootState, rootGetters) {
			return {
				name: getters['BaseInfo/campaignName'],
				id: getters['BaseInfo/campaignID'],
				status: getters['BaseInfo/campaignStatus'],
				limitReached: getters['BaseInfo/campaignLimitReached'],
				rollType: getters['RollType/selectedRollType'],
				ssp: getters['Ssps/selectedSsps'],
				segment: {
					dmpEngine: null,
					appCheckIDs: null,
					audienceIDs: null,
					cashBoxDataIDs: null,
					retargetingBlack: false,
					retargetingSegments: null,
					selfServiceIDs: null,
					user: null,
					visitHistoryIDs: null,
					dmp: getters['Segments/dmpEngine'],
					panelDomainsIDs: rootGetters['ProviderPanel/Segments/selectedPanelSegments'].length === 0
						? ['']
						: rootGetters['ProviderPanel/Segments/selectedPanelSegments'],
				},
				geo: getters['Geo/selectedFinalGeo']('MainGeo'),
				restrictiveGeo: getters['Geo/selectedFinalGeo']('RestrictiveGeo'),
				geoByLevel: getters['Geo/fullGeoByLevel'],
				lists: {
					blackDomainList: false,
					domainList: [],
					blackBundleList: false,
					blackDeviceList: false,
					blackIPList: false,
					blackMacList: false,
					blackUAList: false,
					bundleList: [],
					deviceList: [],
					ipList: [],
					macList: [],
					platformList: [],
					uaList: [],
				},
				siteCategories: [...getters['SiteCategories/selectedSiteCategories']],
				blackSiteCategories: getters['SiteCategories/blackSiteCategories'],
				emotionalBackground: getters['EmotionalBckg/resultedEmotionalBackground'],
				brandSafety: getters['BrandSafety/selectedBrandSafety'],
				deviceInfo: {
					operators: getters['Operators/finalOperators'],
					deviceTypes: getters['DeviceType/selectedDeviceType'],
					osbid: getters['OperatingSystem/selectedOsbid'],
					batteryCharge: null,
					connectionTypes: null,
					hwv: null,
					langs: null,
					make: getters['DeviceManufactures/resultDeviceManufactures'],
					models: null,
					osv: getters['OsVersion/selectedOsVersion'],
				},
				calendar: getters['Calendar/finalCalendar'],
				limit: getters['Limits/finalLimits'],
				bidType: getters['BidType/finalBidType'],
				buyingType: getters['BuyingType/finalBuyingType'],
				bidStrategy: 'none',
				blacklistAppCategories: false,
				blacklistAppInfoCategories: false,
				blacklistBundles: false,
				blacklistDomains: false,
				userInfo: {
					ages: 1,
					agesS: [],
					gender: null,
				},
				type: getters['BaseInfo/campaignType'],
				uniformClicks: false,
				trafType: getters['TrafType/resultedTrafType'],
				bidFloor: false,
				bidFloorIndex: 1,
				strategy: [0, 1],
				superGeo: null,
				targetCampaign: getters['TargetSettings/resultTargetCampaign'],
				telecomOperator: null,
				saveCords: false,
				saveUsers: null,
				os: null,
				owner: '',
				publishers: [''],
				lookALikeProc: 0,
				device: 'all',
				extraSSP: null,
				forSizmek: false,
				fraudScoreName: getters['FraudScore/resultFraudScoreName'],
				group: '000000000000000000000000',
				inventoryOnly: false,
				kpi: getters['Kpi/finalKpi'],
				retargeting: {
					whiteList: getters['Retargeting/selectedRetargeting']('whiteList'),
					blackList: getters['Retargeting/selectedRetargeting']('blackList'),
				},
				wbLists: {
					appCategories: {
						black: [],
						white: [],
					},
					appInfoCategories: {
						black: [],
						white: [],
					},
					bundle: {
						black: [],
						white: [],
					},
					domain: {
						black: [],
						white: [],
					},
					bigLists: {
						platform: {
							black: [],
							white: [],
						},
						mac: {
							black: [],
							white: [],
						},
						ip: {
							black: [...getters['IpsList/selectedIpsLists']('black')],
							white: [...getters['IpsList/selectedIpsLists']('white')],
						},
						domain: {
							black: [...getters['DomainsList/selectedDomainsLists']('black')],
							white: [...getters['DomainsList/selectedDomainsLists']('white')],
						},
						device: {
							black: [],
							white: [],
						},
						bundle: {
							black: [],
							white: [],
						},
						ua: {
							black: [],
							white: [],
						},
					},
				},
			};
		},
	},
	mutations: {},
	actions: {
		async updateCampaign({ getters }) {
			const campaignData = getters.translatedToBackendKeysCampaignData;
			return this.$fetch.campaignAPI.updateCampaignSettings(campaignData);
		},
		async createCampaign({ getters }) {
			const campaignData = { ...getters.translatedToBackendKeysCampaignData };
			const banners = [...getters['Banners/banners']];
			delete campaignData.id;
			return this.$fetch.campaignAPI.createCampaign(campaignData, banners);
		},
		updateCampaignSegments({ commit, state }, campaignData) {
			const campaignInterests = [];
			const genderSegments = Object.keys(state.Segments.gender);
			const agesSegments = Object.keys(state.Segments.ages);
			const campaignDmpSegments = campaignData.segment.dmp ?? [];
			genderSegments.forEach((segmentID) => {
				const isSegmentSelected = campaignDmpSegments.indexOf(segmentID) !== -1;
				commit('Segments/UPDATE_GENDER', { fieldName: segmentID, value: isSegmentSelected });
			});
			agesSegments.forEach((segmentID) => {
				const isSegmentSelected = campaignDmpSegments.indexOf(segmentID) !== -1;
				commit('Segments/UPDATE_AGE', { fieldName: segmentID, value: isSegmentSelected });
			});
			campaignDmpSegments.forEach((segmentID) => {
				if (segmentID.trim()) {
					if (!genderSegments.includes(segmentID) && !agesSegments.includes(segmentID)) {
						campaignInterests.push(segmentID);
					}
				}
			});
			commit('Segments/UPDATE_SELECTED_INTERESTS', campaignInterests);
		},
		updateCampaignStores({ commit, dispatch, state }, campaignData) {
			campaignData.retargeting.whiteList = campaignData.retargeting.whiteList ? campaignData.retargeting.whiteList : [];
			campaignData.retargeting.blackList = campaignData.retargeting.blackList ? campaignData.retargeting.blackList : [];
			campaignData.rollType = campaignData.rollType ? campaignData.rollType : ['pre-roll', 'content-roll'];
			campaignData.ssp = campaignData.ssp ? campaignData.ssp : [];
			campaignData.segment.panelDomainsIDs = !campaignData.segment.panelDomainsIDs
				? []
				: campaignData.segment.panelDomainsIDs;

			commit('BaseInfo/UPDATE_CAMPAIGN_NAME', campaignData.name);
			commit('BaseInfo/UPDATE_CAMPAIGN_ID', campaignData.id);
			commit('BaseInfo/UPDATE_CAMPAIGN_STATUS', campaignData.status);
			commit('BaseInfo/UPDATE_CAMPAIGN_STATUS', campaignData.status);
			commit('BaseInfo/UPDATE_CAMPAIGN_TYPE', campaignData.type);
			commit('BaseInfo/UPDATE_CAMPAIGN_LIMIT_REACHED', campaignData.limitReached);
			commit('TrafType/UPDATE_TRAF_TYPE', campaignData.trafType);
			commit('BaseInfo/UPDATE_IS_BRAND_LIFT', campaignData.isBrandLift);
			commit('RollType/UPDATE_ROLL_TYPE', { fieldName: 'preRoll', value: campaignData.rollType.includes('pre-roll') });
			commit('RollType/UPDATE_ROLL_TYPE', {
				fieldName: 'contentRoll',
				value: campaignData.rollType.includes('content-roll'),
			});
			dispatch('updateCampaignSegments', campaignData);
			commit('Geo/UPDATE_FULL_GEO_BY_LEVEL', { campaignGeoByLevel: campaignData.geoByLevel });
			Object.keys(state.Ssps.ssps).forEach((sspSysName) => {
				commit('Ssps/UPDATE_SSPS', { fieldName: sspSysName, value: campaignData.ssp.includes(sspSysName) });
			});
			commit('DomainsList/UPDATE_SELECTED_DOMAINS', campaignData.wbLists.bigLists.domain);
			commit('IpsList/UPDATE_SELECTED_IP_BIG_LISTS', campaignData.wbLists.bigLists.ip);
			commit('SiteCategories/UPDATE_SELECTED_SITE_CATEGORIES', campaignData.siteCategories.filter((category) => category !== '000000000000000000000000'));
			commit('SiteCategories/UPDATE_BLACK_SITE_CATEGORIES', campaignData.blackSiteCategories);
			commit('TargetSettings/UPDATE_TARGET_CAMPAIGN', campaignData.targetCampaign === '' ? '000000000000000000000000' : campaignData.targetCampaign);
			Object.keys(state.BrandSafety).forEach((fieldName) => {
				const isSelected = campaignData.brandSafety?.includes(state.BrandSafety[fieldName].id);
				commit('BrandSafety/UPDATE_BRAND_SAFETY', { fieldName, value: isSelected });
			});
			const selectedDeviceType = campaignData.deviceInfo.deviceTypes;
			if (selectedDeviceType) {
				commit('DeviceType/UPDATE_DEVICE_TYPE', {
					fieldName: 'desktop',
					value: selectedDeviceType.includes(2),
				});
				commit(
					'DeviceType/UPDATE_DEVICE_TYPE',
					{
						fieldName: 'mobile',
						value: selectedDeviceType.includes(1) || selectedDeviceType.includes(4) || selectedDeviceType.includes(4),
					},
				);
				commit(
					'DeviceType/UPDATE_DEVICE_TYPE',
					{
						fieldName: 'smartTV',
						value: selectedDeviceType.includes(3),
					},
				);
			}
			commit('OperatingSystem/UPDATE_OPERATING_SYSTEM', campaignData.deviceInfo.osbid);
			commit('Operators/UPDATE_OPERATORS', campaignData.deviceInfo.operators);
			commit('OsVersion/UPDATE_OS_VERSION', campaignData.deviceInfo.osv);
			commit('DeviceManufactures/UPDATE_DEVICE_MANUFACTURES', campaignData.deviceInfo.make);
			commit('Calendar/UPDATE_DATES', campaignData.calendar.dates[campaignData.calendar.dates.length - 1]);
			dispatch('Calendar/updateWeekDays', campaignData.calendar.week_days);
			commit('Limits/UPDATE_ALL_LIMITS', campaignData.limit);
			commit('BidType/UPDATE_BID_TYPE', campaignData.bidType);
			commit('BuyingType/UPDATE_BUYING_TYPE', campaignData.buyingType);
			commit('Kpi/UPDATE_KPI', campaignData.kpi);
			commit('Retargeting/UPDATE_CAMPAIGN_RETARGETING', campaignData.retargeting);
			commit('ProviderPanel/Segments/UPDATE_SELECTED_PANEL_SEGMENTS', campaignData.segment.panelDomainsIDs, { root: true });
			commit('FraudScore/UPDATE_FRAUD_SCORE_NAME', campaignData.fraudScoreName);
			commit('EmotionalBckg/UPDATE_EMOTIONAL_BACKGROUND_BY_CAMPAIGN', campaignData.emotionalBackground);
		},
		setDefaultCampaignData({ commit, dispatch, rootGetters }) {
			commit('BaseInfo/SET_DEFAULT_BASE_INFO');
			commit('RollType/SET_DEFAULT_ROLL_TYPE');
			commit('TrafType/SET_DEFAULT_TRAF_TYPE');
			commit('Segments/SET_DEFAULT_SELECTED_AGES');
			commit('Segments/SET_DEFAULT_SELECTED_GENDER');
			commit('Segments/SET_DEFAULT_SELECTED_INTERESTS');
			commit('Geo/SET_DEFAULT_GEO_BY_LEVEL', 'MainGeo');
			commit('Geo/SET_DEFAULT_GEO_BY_LEVEL', 'RestrictiveGeo');
			commit('DomainsList/SET_DEFAULT_DOMAIN_LIST');
			commit('IpsList/SET_DEFAULT_IP_LISTS');
			commit('SiteCategories/SET_DEFAULT_SITE_CATEGORIES');
			commit('EmotionalBckg/SET_DEFAULT_EMOTIONAL_BACKGROUND');
			commit('BrandSafety/SET_DEFAULT_BRAND_SAFETY');
			commit('DeviceType/SET_DEFAULT_DEVICE_TYPE');
			commit('OperatingSystem/SET_DEFAULT_OPERATING_SYSTEM');
			commit('Operators/SET_DEFAULT_OPERATORS');
			commit('DeviceManufactures/SET_DEFAULT_ALL_DEVICE_MANUFACTURES');
			commit('DeviceManufactures/SET_DEFAULT_DEVICE_MANUFACTURES');
			commit('OsVersion/SET_DEFAULT_OS_VERSION');
			dispatch('Calendar/setDefaultCalendar');
			dispatch('Limits/setDefaultAllLimits');
			commit('BidType/SET_DEFAULT_BID_TYPE');
			commit('BuyingType/SET_DEFAULT_BUYING_TYPE');
			commit('Banners/SET_DEFAULT_BANNERS');
			commit('Banners/SET_DEFAULT_TARGET_CAMPAIGN_BANNERS');
			commit('Kpi/SET_DEFAULT_KPI');
			dispatch('Retargeting/setDefaultCampaignRetargetingStore');
			commit('ProviderPanel/Segments/SET_DEFAULT_SEGMENTS', null, { root: true });
			commit('ProviderPanel/Segments/SET_DEFAULT_SELECTED_PANEL_SEGMENTS', null, { root: true });
			commit('FraudScore/SET_DEFAULT_FRAUD_SCORE_NAME');
			commit('TargetSettings/SET_DEFAULT_TARGET_SETTINGS');
			if (rootGetters['GlobalHooks/isSspsBlockVisible']) {
				commit('Ssps/DESELECT_ALL_SSPS');
			} else {
				commit('Ssps/SELECT_ALL_SSPS');
			}
		},
		async getCampaignData({ dispatch }, campaignID) {
			const [err, result] = await this.$fetch.campaignAPI.getCampaignData(campaignID);
			if (err) {
				return;
			}
			dispatch('updateCampaignStores', result);
		},
		async getHelperData({ dispatch, getters, rootGetters }) {
			const campaignID = getters['BaseInfo/campaignID'];
			const targetCampaignId = getters['TargetSettings/resultTargetCampaign'];
			const fullCurrentCabinet = rootGetters['User/fullCurrentCabinet'];
			const isSuccess = true;
			try {
				await Promise.all([
					dispatch('Segments/getTaxonomy'),
					dispatch('User/getUserCabinets', null, { root: true }),
					dispatch('DomainsList/getBigList'),
					dispatch('IpsList/getIpBigLists'),
					dispatch('SiteCategories/getSiteCategories'),
					dispatch('Geo/getGeoStoresData'),
					dispatch('Operators/getOperators'),
					dispatch('DeviceManufactures/getAllDeviceManufactures'),
					dispatch('Operators/getOperators'),
					dispatch('Banners/getBanners', { campaignId: campaignID, type: 'current' }),
					dispatch('PlayerCustomization/getPlayerCustomizationConfigs', true, { root: true }),
					dispatch('Retargeting/getRetargetingSegments'),
					dispatch('ProviderPanel/Segments/getSegments', null, { root: true }),
				]);
				if (targetCampaignId !== '000000000000000000000000') {
					await dispatch('Banners/getBanners', { campaignId: targetCampaignId, type: 'target' });
					await dispatch('TargetSettings/getCampaignOwner', { campaignId: targetCampaignId });
					await dispatch('CampaignList/getLightCampaigns', getters['TargetSettings/ownerAccountId'], { root: true });
					return isSuccess;
				}

				await dispatch('CampaignList/getLightCampaigns', fullCurrentCabinet.accountId, { root: true });
				return isSuccess;
			} catch (err) {
				console.error(err);
				return !isSuccess;
			}
		},
	},
	modules: {
		BaseInfo,
		RollType,
		Segments,
		Geo,
		Ssps,
		DomainsList,
		IpsList,
		SiteCategories,
		EmotionalBckg,
		BrandSafety,
		DeviceType,
		OperatingSystem,
		Operators,
		BidType,
		BuyingType,
		Calendar,
		Limits,
		Banners,
		Kpi,
		Retargeting,
		TrafType,
		FraudScore,
		TargetSettings,
		DeviceManufactures,
		OsVersion,
	},
};
